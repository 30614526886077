import React, { Component } from 'react';
import Topbar from '../partials/Topbar';
import Footer from '../partials/Footer';

import './Scrumevents.css';

import heroImg from '../imgs/scrum-event-sheets-hero.png';
import event1Img from '../imgs/scrum-event-sheets-1.png';
import event2Img from '../imgs/scrum-event-sheets-2.png';

export default class Scrumevents extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let doClickout = () => {
            window.open('https://gumroad.com/l/scrum-events', '_blank');
        };

        return (
            <div>
                <Topbar activeNav="events" />
                <div>
                    {/* Hero Container */}
                    <div className="hero-container">
                        <div>
                            <div className="hero-title-1">SCRUM</div>
                            <div className="hero-title-2">EVENT SHEETS</div>
                            <div className="hero-info-1">
                                Easy to understand, step-by-step sheets covering how to run Scrum
                                events.
                            </div>
                            <div className="hero-info-2">
                                <div>
                                    Each checklist covers meeting agenda, outcome artifacts,
                                    definitions and
                                </div>
                                step-by-step instructions for running each meeting.
                            </div>
                            <div>
                                <a
                                    href="https://www.producthunt.com/posts/scrum-event-sheets?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-scrum-event-sheets"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=284869&theme=dark"
                                        alt="Scrum Event Sheets - Templates providing everything you need to run Scrum events. | Product Hunt"
                                        style={{
                                            width: '250px',
                                            height: '54px',
                                            marginTop: '30px',
                                            borderRadius: '5px',
                                            border: '1px solid #4f565f',
                                        }}
                                        width="250"
                                        height="54"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="hero-image-container">
                        <img src={heroImg} alt="Scrum Study Cards" />
                    </div>
                    <div className="action-container">
                        <div>
                            <div className="action-header">PDF FILES</div>
                            <div className="action-text">$15</div>
                            <div className="action-btn" onClick={doClickout}>
                                BUY NOW
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="info-container">
                        <div className="section-title" style={{ paddingBottom: '20px' }}>
                            ABOUT THE SHEETS
                        </div>
                        <div className="info-bullets">
                            <ul>
                                <li>
                                    <b>5 PDF checklists</b> covering each of the five Scrum events.
                                </li>
                                <li>
                                    <b>Step-by-step instructions</b> for running each meeting.
                                </li>
                                <li>
                                    <b>2020 Scrum guide</b> updates are covered and included.
                                </li>
                                <li>
                                    <b>High quality PDF</b> ready for printing.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hero-image-container">
                        <img src={event1Img} alt="Scrum Event Sheets" />
                    </div>
                    <div className="info-container">
                        <div className="section-title" style={{ paddingBottom: '20px' }}>
                            INCLUDED EVENT SHEETS
                        </div>
                        <div className="events-toc-bullets">
                            <ul>
                                <li>
                                    <b>THE SPRINT</b>
                                </li>
                                <br></br>
                                <li>
                                    <b>SPRINT PLANNING</b>
                                    <ul>
                                        <li>Agenda</li>
                                        <li>Outcome</li>
                                        <li>Definitions</li>
                                        <li>Before the Meeting</li>
                                        <li>Running the Meeting</li>
                                        <li>After the Meeting</li>
                                    </ul>
                                </li>
                                <br></br>
                                <li>
                                    <b>DAILY SCRUM</b>
                                    <ul>
                                        <li>Agenda</li>
                                        <li>Outcome</li>
                                        <li>Running the Meeting</li>
                                    </ul>
                                </li>
                                <br></br>
                                <li>
                                    <b>SPRINT REVIEW</b>
                                    <ul>
                                        <li>Agenda</li>
                                        <li>Outcome</li>
                                        <li>Definitions</li>
                                        <li>Before the Meeting</li>
                                        <li>Running the Meeting</li>
                                        <li>After the Meeting</li>
                                    </ul>
                                </li>
                                <br></br>
                                <li>
                                    <b>SPRINT RETROSPECTIVE</b>
                                    <ul>
                                        <li>Agenda</li>
                                        <li>Outcome</li>
                                        <li>Definitions</li>
                                        <li>Before the Meeting</li>
                                        <li>Running the Meeting</li>
                                        <li>After the Meeting</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <br></br>
                    <hr></hr>
                    <div className="action-container">
                        <div>
                            <div className="action-header">PDF FILES</div>
                            <div className="action-text">$15</div>
                            <div className="action-btn" onClick={doClickout}>
                                BUY NOW
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="hero-image-container">
                        <img src={event2Img} alt="Scrum Event Sheets 2" style={{ border: '0' }} />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
