import React, { Component } from 'react';
import Topbar from '../partials/Topbar';
import Footer from '../partials/Footer';

import './Scrumcards.css';

import heroImg from '../imgs/scrum-study-cards-hero.png';
import deckImg from '../imgs/scrum-study-cards-deck.png';
import deck2Img from '../imgs/scrum-study-cards-deck-2.png';

export default class Scrumcards extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let doClickout = () => {
            window.open('https://gumroad.com/l/scrum-study-cards', '_blank');
        };

        return (
            <div>
                <Topbar activeNav="cards" />
                <div>
                    {/* Hero Container */}
                    <div className="hero-container">
                        <div>
                            <div className="hero-title-1">SCRUM</div>
                            <div className="hero-title-2">STUDY CARDS</div>
                            <div className="hero-info-1">
                                An easy to understand overview of the Scrum framework.
                            </div>
                            <div className="hero-info-2">
                                <div>
                                    Created to help those looking to become certified as a Scrum
                                    Master,
                                </div>
                                Product Owner, Developer or just want a better understanding of
                                Scrum.
                            </div>
                            <div>
                                <a
                                    href="https://www.producthunt.com/posts/scrum-study-cards?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-scrum-study-cards"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=282119&theme=dark"
                                        alt="Scrum Study Cards - An easy to understand overview of the Scrum framework. | Product Hunt"
                                        style={{
                                            width: '250px',
                                            height: '54px',
                                            marginTop: '30px',
                                            borderRadius: '5px',
                                            border: '1px solid #4f565f',
                                        }}
                                        width="250"
                                        height="54"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="hero-image-container">
                        <img src={heroImg} alt="Scrum Study Cards" />
                    </div>
                    <div className="action-container">
                        <div>
                            <div className="action-header">PDF FILE</div>
                            <div className="action-text">$15</div>
                            <div className="action-btn" onClick={doClickout}>
                                BUY NOW
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="info-container">
                        <div className="section-title" style={{ paddingBottom: '20px' }}>
                            ABOUT THE CARDS
                        </div>
                        <div className="info-bullets">
                            <ul>
                                <li>
                                    <b>30 PDF cards</b> covering the complete Scrum framework.
                                </li>
                                <li>
                                    <b>Designed for easy reference</b> in a portable set of cards.
                                </li>
                                <li>
                                    <b>2020 Scrum guide</b> updates are covered and included.
                                </li>
                                <li>
                                    <b>High quality PDF</b> ready for printing.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hero-image-container">
                        <img src={deckImg} alt="Scrum Study Cards Deck" />
                    </div>
                    <div className="info-container">
                        <div className="section-title" style={{ paddingBottom: '20px' }}>
                            TABLE OF CONTENTS
                        </div>
                        <div className="toc-bullets">
                            <ul>
                                <li>
                                    <b>DEFINITION</b>
                                </li>
                                <br></br>
                                <li>
                                    <b>THEORY</b>
                                    <ul>
                                        <li>Transparency</li>
                                        <li>Inspection</li>
                                        <li>Adaptation</li>
                                    </ul>
                                </li>
                                <br></br>
                                <li>
                                    <b>VALUES</b>
                                    <ul>
                                        <li>Commitment</li>
                                        <li>Focus</li>
                                        <li>Openness</li>
                                        <li>Respect</li>
                                        <li>Courage</li>
                                    </ul>
                                </li>
                                <br></br>
                                <li>
                                    <b>OVERVIEW</b>
                                </li>
                                <br></br>
                                <li>
                                    <b>TEAM</b>
                                    <ul>
                                        <li>Scrum Master</li>
                                        <li>Product Owner</li>
                                        <li>Developers</li>
                                    </ul>
                                </li>
                                <br></br>
                                <li>
                                    <b>EVENTS</b>
                                    <ul>
                                        <li>The Sprint</li>
                                        <li>Sprint Planning</li>
                                        <li>Daily Scrum</li>
                                        <li>Sprint Review</li>
                                        <li>Sprint Retrospective</li>
                                    </ul>
                                </li>
                                <br></br>
                                <li>
                                    <b>ARTIFACTS</b>
                                    <ul>
                                        <li>Product Backlog</li>
                                        <li>Sprint Backlog</li>
                                        <li>Increment</li>
                                    </ul>
                                </li>
                                <br></br>
                                <li>
                                    <b>SPRINT CYCLE</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <br></br>
                    <hr></hr>
                    <div className="action-container">
                        <div>
                            <div className="action-header">PDF FILE</div>
                            <div className="action-text">$15</div>
                            <div className="action-btn" onClick={doClickout}>
                                BUY NOW
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="hero-image-container">
                        <img
                            src={deck2Img}
                            alt="Scrum Study Cards Deck 2"
                            style={{ border: '0' }}
                        />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
