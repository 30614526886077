import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../imgs/topbar-logo.png';

import './Topbar.css';

export default class Topbar extends Component {
    render() {
        let navClass1 = '';
        let navClass2 = '';
        let navClass3 = '';

        switch (this.props.activeNav) {
            case 'home':
                navClass1 = 'nav-active';
                navClass2 = '';
                navClass3 = '';
                break;
            case 'cards':
                navClass1 = '';
                navClass2 = 'nav-active';
                navClass3 = '';
                break;
            case 'events':
                navClass1 = '';
                navClass2 = '';
                navClass3 = 'nav-active';
                break;
            default:
                break;
        }

        return (
            <div className="topbar-container">
                <div className="logo-container">
                    <Link to="/" className="logoLink">
                        <span className="logo-img">
                            <img src={Logo} alt="Let's Do Scrum" />
                        </span>
                        <div>Let's Do Scrum</div>
                    </Link>
                </div>
                <div>
                    <div className="nav-item">
                        <Link to="/" className={navClass1}>
                            HOME
                        </Link>
                        <Link to="/scrum-cards" className={navClass2}>
                            SCRUM CARDS
                        </Link>
                        <Link to="/scrum-events" className={navClass3}>
                            EVENT SHEETS
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
