import React from 'react';

import './Footer.css';

let copyrightYear = new Date().getFullYear();

export default function Footer() {
    return (
        <div className="footer-container">
            <div>© {copyrightYear} Let's Do Scrum. All Rights Reserved.</div>
        </div>
    );
}
