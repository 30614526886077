import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './views/Home';
import Scrumcards from './views/Scrumcards';
import Scrumevents from './views/Scrumevents';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path={'/'} component={Home} />
                <Route exact path={'/scrum-cards'} component={Scrumcards} />
                <Route exact path={'/scrum-events'} component={Scrumevents} />
            </Switch>
        </Router>
    );
}

export default App;
