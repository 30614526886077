import React from 'react';
import { Link } from 'react-router-dom';
import Topbar from '../partials/Topbar';
import Footer from '../partials/Footer';

import './Home.css';

import scrumCardsImage from '../imgs/scrum-study-cards-hero.png';
import scrumEventsImage from '../imgs/scrum-event-sheets-hero.png';

export default function Home() {
    return (
        <div>
            <Topbar activeNav="home" />
            <div>
                {/* Main Container */}
                <div className="main-container">
                    <div>
                        <div className="home-section-title">Products</div>
                        <div>
                            <div className="product-container">
                                <Link to="/scrum-cards">
                                    <img src={scrumCardsImage} alt="Scrum Study Cards" />
                                </Link>
                            </div>
                            <div className="product-title">
                                <Link to="/scrum-cards">Scrum Study Cards</Link>
                            </div>
                            <div className="product-info">
                                An easy to understand overview of the Scrum framework.
                            </div>
                        </div>
                        <br></br>
                        <div>
                            <div className="product-container">
                                <Link to="/scrum-events">
                                    <img src={scrumEventsImage} alt="Scrum Event Sheets" />
                                </Link>
                            </div>
                            <div className="product-title">
                                <Link to="/scrum-cards">Scrum Event Sheets</Link>
                            </div>
                            <div className="product-info">
                                Step-by-step templates providing everything you need to run
                                successful Scrum events.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
